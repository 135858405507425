header {
  overflow: hidden;
  padding: 3rem 0rem 0rem 0rem;
  display: flex;
  justify-content: center;
}

.header_container {
  max-width: 75%;
  text-align: center;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-download-mobile {
  display: none;
}

.btn-download {
  position: absolute;
  left: 63%;
  bottom: 60%;
  z-index: 2;
  background: var(--color-primary);
  color: var(--color-white);
  width: max-content;
  display: inline-block;
  color: var(--color-white);
  padding: 0.5rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn-download:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.typewriter-effect {
  font-size: var(--font-size-two);
  color: var(--color-primary-variant);
}

/*-------------HEADER SOCIALS----------------*/

.header_socials {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  position: absolute;
  left: -4rem;
  bottom: 6rem;
}

.header_socials::after {
  content: "Linkedin ";
  color: var(--color-primary-variant);
  font-size: 0.8rem;
  transform: rotate(-90deg);
  margin-top: 6px;
}

.header_socials a {
  color: white;
  font-size: 1.6rem;
}

.header_socials a:hover {
  color: var(--color-primary-variant);
}

/*----------------------ME-----------------*/
.me {
  max-width: 35%;
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.me img {
  margin-bottom: -2rem;
}

.scroll-down-email {
  position: absolute;
  transform: rotate(90deg);
  right: -6.5rem;
  bottom: 8rem;
  font-size: 0.8em;
  font-weight: 300;
  color: var(--color-primary-variant);
}

/*===========MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {
  header {
    max-width: 100vw;
    margin: 0;
  }

  .me {
    min-width: 80%;
  }

  .header_container {
    padding: 0;
    margin: 0;
  }

  .btn-download {
    left: 73%;
  }

  .header_socials,
  .scroll-down-email {
    display: none;
  }
}

/*===========MEDIA QUERIES (SMALL DEVICES) =======*/
@media screen and (max-width: 600px) {
  .btn-download {
    display: none;
  }

  .btn-download-mobile {
    display: inline-block;
    position: absolute;
    left: 78%;
    bottom: 48%;
    z-index: 2;
    background: #8251a8cd;
    color: var(--color-white);
    width: max-content;
    color: var(--color-white);
    padding: 0.4rem 0.7rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
  }
}
