footer {
    background: var(--color-primary);
    height: 40vh;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.permalinks {
    width: 100%;
    margin-top: 4em;
    display: flex;
    gap: 5rem;
    list-style: none;
    justify-content: center;
}

.permalinks a {
    color: white;
    font-size: var(--font-size-three);
}

.permalinks a:hover {
    color: var(--color-primary-variant);
}

.footer--socials {
    display: flex;
    justify-content: center;
    margin-top: 2em;
    gap: 1.2rem;
}

.footer--socials a {
    display: flex;
    align-items: center;
    padding: .8rem;
    border-radius: .4rem;
    color: white;
    font-size: 1.3rem;
    background: rgba(0, 0, 0, 0.508);
}

.footer--socials a:hover {
    color: var(--color-primary-variant);
}

/*===========MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {}

/*===========MEDIA QUERIES (SMALL DEVICES) =======*/
@media screen and (max-width: 600px) {
    footer {
        margin-top: 2em;
        background: var(--color-primary);
        height: 40vh;
    }

    .permalinks {
        gap: .8rem;
        padding-left: .2em;
        padding-right: .2em;
    }

}