.contact--section {
  border: 1px solid var(--color-primary);
  padding: 3rem 0rem;
  border-top-style: none;
}

.contact--container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 1rem;
  align-items: center;
  margin-bottom: 2rem;
}

input {
  color: white;
  padding-left: 0.7rem;
  width: 50%;
  height: 40px;
  border-radius: 0.4rem;
  background: transparent;
  border: 1px solid var(--color-primary-variant);
}

::placeholder {
  color: rgba(255, 252, 252, 0.692);
  padding-left: 0.7em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

button {
  background: var(--color-primary);
}

textarea {
  color: white;
  border-radius: 0.4rem;
  background: transparent;
  border: 1px solid var(--color-primary-variant);
  padding-top: 1rem;
  width: 50%;
  padding-left: 0.7rem;
}

.contact-options {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.contact-option {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  border-radius: 0.5rem;
  align-items: center;
  background: #8251a860;
}

.contact-option a {
  border-radius: 0.4rem;
  padding: 0.5em;
  color: var(--color-primary-variant);
  font-size: 0.8rem;
}

.contact-option a:hover {
  color: var(--color-primary);
  background: white;
}

.contact-icons {
  font-size: 1.5rem;
}

/*===========MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {
}

/*===========MEDIA QUERIES (SMALL DEVICES) =======*/
@media screen and (max-width: 600px) {
  .contact--section {
    padding-top: 0;
    margin-top: 4em;
    border: none;
  }

  .contact-options {
    margin-top: 3rem;

    flex-direction: column;

    align-items: center;
  }

  .contact-option {
    padding-top: 1rem;

    width: 90%;
  }

  input,
  textarea {
    width: 90%;
  }
}
