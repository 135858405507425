.about--section {
    padding: 4rem 11rem;
    border: 1px solid var(--color-primary);
    border-bottom-style: none;
}

.description {
    padding-top: 1rem;
}

.description .btn {
    margin-top: 2rem;
    background: var(--color-primary);
}

.about--section h1 {
    text-align: left;

}

.about--me--title {
    font-size: 1.3rem;

}

.description p {
    font-size: 1rem;
}

/*===========MEDIA QUERIES (MEDIUM DEVICES) =======*/
@media screen and (max-width: 1024px) {
    .about--section {
        margin: 0;
        padding: 2em 5em;
    }
}

/*===========MEDIA QUERIES (SMALL DEVICES) =======*/
@media screen and (max-width: 600px) {

    .about--section {
        margin: 0;
        padding: 1em;
    }

    .about--section h1 {
        padding: 1rem 0rem 0rem 0rem;
        margin: 0;

    }


}